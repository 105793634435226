@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  @font-face {
    font-family: 'Volkhov';
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: 'Inter';
    font-weight: 300 600;
    font-display: swap;
    font-style: normal;
  }
}

@layer utilities {
  .spin-button-none::-webkit-outer-spin-button,
  .spin-button-none::-webkit-inner-spin-button {
    appearance: none;
  }
}

@layer components {
  .rounded-curve::after {
    content: ' ';
    height: 100px;
    width: 120vw;
    position: absolute;
    top: -100px;
    border-radius: 50%;
    box-shadow: 0 40px 0 0 #f8f9ff;
    left: -14vw;
    z-index: -1;
  }
  .outline-stroke {
    -webkit-text-stroke: 1px #e2e6f8;
  }
}

/* flatpickr overrides */
span.flatpickr-day.selected,
span.flatpickr-day.selected:hover {
  background: #3350f7;
  border-color: #3350f7;
  border-radius: 2px;
}

div.flatpickr-weekdays {
  margin-bottom: 0.25rem;
}

span.flatpickr-day.today,
span.flatpickr-day:hover {
  border-radius: 2px;
}

span.flatpickr-day {
  line-height: 2.7;
}

/* React phone input overrides */
.react-tel-input .search {
  padding: 16px 16px 12px 16px !important;
  background-color: 'white';
  z-index: 1;
  width: 100%;
  text-transform: capitalize;
  border-bottom: 1px solid grey-100 !important;
  display: inline-flex !important;
}
.react-tel-input .search-box {
  font-size: 16px !important;
  margin: 0px 10px !important;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 0 !important;
  width: 100%;
}
.react-tel-input .search::before {
  content: url(../public/assets/Search.png);
  width: 20px;
  height: 20px;
  align-self: center;
}
.react-tel-input .country-list .country {
  padding: 8px 20px !important;
}
.react-tel-input .selected-flag {
  background-color: inherit !important;
}
.react-tel-input .selected-flag .flag {
  transform: scale(1.2);
  padding-right: 8px;
}
.react-tel-input .country-list {
  border: 1px solid #05176b;
}
.react-tel-input .country-list .country .flag {
  transform: scale(1.5);
  margin: 2px 12px 0px 0px !important;
}
.react-tel-input .country-list .country .country-name {
  margin-left: 10px;
  margin-right: 12px;
}
.react-tel-input .country-list .country .dial-code {
  font-style: italic;
}
